form {
    max-width: 100%;
    margin: 0;
}

.busqueda {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.crearFiltrado {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.crearFiltrado label {
    padding-left: 10px;
}

.crearFiltrado input {
    padding-left: 10px;
}

.crearFiltrado button {
    display: block;
    width: 100px;
    padding: 10px;
    margin-bottom: 15px; /* Separación entre inputs */
    box-sizing: border-box;
}