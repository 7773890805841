header {
    background-color: #004d99;
    color: white;
    padding: 20px;
    text-align: center;
}

.sidebar {
    padding: 100px 20px 0 20px;
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #004d99;
}

.sidebar h2 {
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 10px;
    text-align: center;
}

.sidebar ul li a {
    text-decoration: none;
    color: white;
    display: block;
    transition: 0.3s;
}

.sidebar ul li a:hover {
    background-color: darkcyan;
}

.content {
    margin-left: 300px;
    padding: 20px 20px 80px;
}

p {
    color: #333;
}

#menu-toggle {
    display: none;
}

@media (max-width: 768px) {

    .content {
        margin-left: 45px;
    }

    .sidebar {
        padding: 10px 20px 0 20px;
        width: 20px;
    }

    ul.visible  {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 77, 153, 1);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hidden {
        display: none;
    }

    #menu-toggle {
        display: block;
        font-size: 40px;
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 1000;
    }

    #menu-toggle:hover {
        cursor: pointer;
    }

    .sidebar ul li {
        margin: 10px 0;
    }

    .visible {
        display: flex;
    }
}