body {
    font-family: Arial, sans-serif;
    background-color: #f7f9fc;
    margin: 0;
    padding: 0;
}

.bop-container {
    padding-bottom: 100px;
    margin: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.bop {
    display: inline-block;
    background-color: lightblue;
    border: 1px solid blue;
    padding: 20px;
    margin: 10px;
    /* Restablecer el tamaño de fuente en los elementos hijos */
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
