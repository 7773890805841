.bopAdmin {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.bopAdmin h2 {
    color: #004d99;
    padding-bottom: 5px;
}

.bopAdmin button {
    margin: 5px 0;
    padding: 10px;
}