body {
    font-family: Arial, sans-serif;
    background-color: #f7f9fc;
    margin: 0;
    padding: 0;
}

.container {
    padding-bottom: 100px;
    margin: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.oposicion {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.etiquetas {
    display: inline-block;
    background-color: lightblue;
    border: 1px solid blue;
    padding: 20px;
    margin: 10px;
    /* Restablecer el tamaño de fuente en los elementos hijos */
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

h2.fecha {
    color: #004d99;
    border-bottom: 2px solid #004d99;
    padding-bottom: 5px;
}
.oposicion h2 {
    color: #004d99;
    padding-bottom: 5px;
}
.oposicion p {
    margin: 5px 0;
}

.buscador {
    display: inline-block;
    width: 250px;
    height: auto;
    padding: 20px;
    margin: 10px;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.buscador button {
    margin-top: 20px;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

@media (max-width: 768px) {

    .search-container {
        display: block;
        width: 80%;
        padding: 5px;
    }

    .buscador {
        display: inline-block;
        width: 100%;
        padding: 5px;
        margin: 10px;
    }

    .container {
        padding-bottom: 50px;
        margin: 5px;
    }

    .oposicion {
        margin: 5px;
    }

}

.search-container label {
    margin-right: 10px;
}

.search-container input {
    margin-right: 10px;
    padding: 5px;
}

#content {
    padding: 20px;
    border-top: 1px solid #ccc;
}

.item {
    margin: 10px 0;
}

div.carga p {
    text-align: center;
}

.separador {
    padding-top: 5px;
    border-top: solid;
}