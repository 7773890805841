.login-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.login-container h2 {
    margin-bottom: 20px;
    color: #333;
}
.login-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #db4437;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
.login-btn:hover {
    background-color: #c23321;
}