#oposicionDetalleId {
    padding-bottom: 100px;
    margin: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

#contenedorDatos {
    margin: 0 auto;
    width: 80%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.listaEdictos {
    margin: 20px auto;
    width: 80%;
    padding: 20px;
    background-color: #cacaca;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.subscription-container {
    margin: 20px auto;
    width: 50%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.subscription-container h2 {
    color: #007bff;
    margin-bottom: 10px;
}

.subscription-container p {
    font-size: 16px;
    margin-bottom: 20px;
}

.subscription-container input[type="email"] {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.subscription-container button {
    width: 120px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.subscription-container button:hover {
    background-color: #0056b3;
}

a {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 768px) {

    #oposicionDetalleId {
        padding-bottom: 50px;
    }

    #contenedorDatos {
        width: 90%;
    }

}