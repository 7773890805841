form {
    max-width: 100%;
    margin: 0;
}

input[type="text"],
input[type="email"],
input[type="file"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px; /* Separación entre inputs */
    box-sizing: border-box;
}

button, select {
    display: block;
    width: 100px;
    padding: 10px;
    margin-bottom: 15px; /* Separación entre inputs */
    box-sizing: border-box;
}

div.content {
    padding-bottom: 200px;
}