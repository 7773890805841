header {
    background-color: #004d99;
    color: white;
    padding: 20px;
    text-align: center;
}

/* Estilo para a imaxe do logo */
.logo {
    max-width: 300px; /* Limita o ancho máximo a 300px */
    width: 100%;      /* Se adapta ao ancho do contedor, pero non excede 300px */
    height: auto;
    margin: 0 auto;   /* Centra a imaxe dentro do contedor */
}

/* Estilos para o menú de navegación */
nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

nav ul li a:hover {
    text-decoration: underline;
}

#menu-toggle {
    display: none;
}

@media (max-width: 768px) {
    nav ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    nav ul li {
        margin: 10px 0;
    }

    #menu-toggle {
        display: block;
        font-size: 40px;
    }

    #menu-toggle:hover {
        cursor: pointer;
    }

    .hidden {
        display: none;
    }
}