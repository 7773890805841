footer {
    background-color: #004d99;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    z-index: 100;
}

.social-icons {
    margin: 10px 0;
}
.social-icons a {
    margin: 0 10px;
    color: grey;
    text-decoration: none;
    font-size: 20px;
}
.social-icons a:hover {
    color: #ddd;
}

@media (max-width: 768px) {

    footer {
        display: none;
    }

}