.oposForms form {
    display: inline-block;
}

.oposForms form button {
    width: 200px;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 15px; /* Separación entre inputs */
    box-sizing: border-box;
}

.search-container-admin {
    margin-left: 300px;
}

@media (max-width: 768px) {

    .search-container-admin {
        margin-left: 50px;
        display: block;
        width: 80%;
        padding: 5px;
    }

    .buscador {
        display: inline-block;
        width: 100%;
        padding: 5px;
        margin: 10px;
    }

}